<script>
export default {
  name: "netaSoftTicketStatusSelect",
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  props: {
    value: Number,
    selectClass: {
      type: String,
      default: 'form-select'
    },
    disabled: Boolean
  },
  methods: {
    handleChange(event) {
      this.model = Number(event.target.value);
      this.$nextTick(() => {
        this.$emit('change', this.model);
      });
    }
  }
}
</script>

<template>
  <div class="mb-3" role="group">
    <label ref="label" class="form-label d-block"> Talep kaydı durumu : </label>
    <div class="">
      <select :disabled="disabled" v-model="model" :class="selectClass" @change="handleChange">
        <option value=1> Beklemede</option>
        <option value=2> Cevaplandı</option>
        <option value=3> Arge</option>
        <option value=4> Kapatıldı</option>
      </select>
    </div>
  </div>
</template>

<style scoped>

</style>