<script>
export default {
  name: "NetaSoftDeleteConfirm",
  props: {
    message: {
      type: String,
      default: 'Seçili kaydı silmek istediğinize emin misiniz?'
    },
    delete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      props: {
        size: 'modal-xs',
        title: 'Dikkat!',
        successClass: 'btn btn-soft-danger',
        successButtonText: 'Evet',
        closeButtonText: 'Kapat'
      }
    }
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async confirmDelete() {
      await this.delete();
      this.$emit('success');
      this.close();
    },
    handleSuccessModal() {
      this.confirmDelete();
    },
  }
}
</script>

<template>
  <page-modal ref="modal" v-bind="props" @success="handleSuccessModal">
    <p>{{ message }}</p>
  </page-modal>
</template>
