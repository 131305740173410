<script>
import formElementMixin from "@/mixins/formElementMixin";

export default {
  name: "NetaSoftInput",
  mixins: [formElementMixin],
  computed: {
    inputClass() {
      return this.type === 'checkbox' ? 'form-check-input' : 'form-control';
    },
    maskDirective() {
      console.log(this.mask && this.mask.length > 0)
      return this.mask && this.mask.length > 0 ? { mask: this.mask } : undefined;
    }
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    mask: {
      type: String,
      default: ''
    },
  },
}
</script>

<template>
  <div class="mb-3" role="group">
    <label ref="label" :for="id" class="form-label d-block"> {{ label }} </label>
    <div class="">
      <input :id="id"
             ref="input"
             v-model="model"
             :class="inputClass"
             :disabled="disabled"
             :placeholder="placeholder"
             :type="type"
             @change="handleInputChange"/>
      <div v-if="hasError" class="error-message">{{ error }}</div>
    </div>
  </div>
</template>