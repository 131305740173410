export default  {
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    hasError() {
      return !!this.error;
    },
    slotData() {
      return {
        value: this.model,
        id: this.id,
        validate: this.validate
      };
    },
  },
  data() {
    return {
      id: 'input-' + Math.random().toString(36).substr(2, 9),
      error: ''
    }
  },
  props: {
    value: {
      type: [String, Boolean, Number],
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    required: [Boolean, Object],
    validation: Function
  },
  methods: {
    handleInputChange() {
      this.$emit('change', this.slotData)
    },
    validate() {
      if (this.required && !this.model) {
        if (typeof this.required === 'object' && this.required.error) {
          this.error = this.required.error;
        } else {
          this.error = 'Bu alan zorunludur.';
        }
        return false;
      }
      if (this.validation) {
        const error = this.validation(this.model);
        if (!error) {
          this.error = '';
          return true;
        }

        if (typeof error !== 'string') {
          throw new error("Validation function must return a string.")
        }
        this.error = error;
        return false;
      }

      this.error = '';
      return true;
    },
    reset() {
      this.model = '';
      this.error = '';
    },
    getModel() {
      return this.model;
    }
  }
}