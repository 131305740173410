<script>
export default {
  name: "NetaSoftPageFooter",

}
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">2024 - 2025 © Netasoft.</div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block"> Design &amp; Develop by Ali & Burak</div>
        </div>
      </div>
    </div>
  </footer>
</template>
