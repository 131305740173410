import VueRouter from "vue-router";
import Routes from "@/routes";

const routes = [
  ...Routes
];

const router = new VueRouter({
  mode: 'hash', // default
  routes,
});

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem('user');
  if (to.path === '/account/login' && user) {
    next('/'); // next();
  } else if (to.path !== '/account/login' && (!user || !JSON.parse(user).tokenBearer)) {
    next('/account/login');
  } else {
    next();
  }
});

export default router;