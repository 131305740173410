<script>
export default {
  name: "NetaSoftForm",
  computed: {
    slotLength() {
      return this.$slots.default.length;
    },
  },
  methods: {
    handleSubmit() {
      if (this.validate()) {
        this.$emit('submit');

      }
    },
    runChildMethod(methodName, callback) {
      const slotLength = this.slotLength;
      for (let i = 0; i < slotLength; i++) {
        const component = this.$slots.default[i].componentInstance;
        const hasMethod = Object.prototype.hasOwnProperty.call(component || {}, methodName);
        if (!hasMethod) {
          continue;
        }
        if (callback ) {
          callback(component[methodName](), component);
        } else {
          component[methodName]();
        }
      }
    },
    validate() {
      let hasError = false;

      this.runChildMethod('validate', (result) => {
        if (!result) {
          hasError = true;
        }
      });

      return !hasError;
    },
    reset() {
      this.runChildMethod('reset');
    },
  }
}
</script>

<template>
  <form ref="form" @submit.prevent="handleSubmit">
    <slot></slot>
  </form>
</template>