import {library, dom} from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faChevronDown,
  faBuildingUser,
  faSearch,
  faPlus,
  faEllipsis,
  faTrashCan,
  faEdit,
  faPen,
  faArrowRightFromBracket,
  faBuilding,
  faInbox,
  faCloudArrowUp,
  faXmark,
  faDeleteLeft,
  faComments,
  faClock,
  faCheckDouble,
  faPaperPlane,
  faDownload,
  faThumbsUp,
  faBell
} from "@fortawesome/free-solid-svg-icons";

library.add(faClock, faBell, faThumbsUp, faDownload, faPaperPlane, faCheckDouble, faBuilding, faDeleteLeft, faComments, faXmark, faCloudArrowUp, faInbox, faBars, faChevronDown, faBuildingUser, faSearch, faPlus, faEllipsis, faTrashCan, faEdit, faPen, faArrowRightFromBracket);
dom.watch();