export default [
  {
    path: '/account/login',
    component: () => import('@/pages/account/login'),
    meta: {
      text: 'Giriş Yap',
      tag: 'noList',
    }
  },
  {
    path: '/',
    component: () => import('@/pages/dashboard'),
    meta: {
      text: 'Anasayfa',
      tag: 'noList',
    }
  },
  {
    path: '/customers',
    name: 'customers',
    meta: {
      icon: 'building-user',
      text: 'Cari Kayıtlar',
      tag: 'admin',
    },
    component: () => import('@/pages/customers'),
  },
  {
    path: '/departments',
    name: 'departments',
    meta: {
      icon: 'building',
      text: 'Departmanlar',
      tag: 'admin',
    },
    component: () => import('@/pages/departments'),
  },
  {
    path: '/tickets',
    name: 'tickets',
    meta: {
      icon: 'inbox',
      text: 'Talepler',
    },
    component: () => import('@/pages/tickets'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      icon: 'bell',
      text: 'Bildirimler',
    },
    component: () => import('@/pages/notifications'),
  }
]