import axios from 'axios';
import {BASE_URL} from "@/helpers/constants";
import {EventBus} from "@/events/eventBus";
import {getToken} from "@/helpers";

const ApiService = axios.create({
  baseURL: BASE_URL,
  headers: {
    'accept': 'text/plain',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

ApiService.interceptors.request.use(config => {
  EventBus.$emit('loading', true);
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

ApiService.interceptors.response.use(response => {
  EventBus.$emit('loading', false);
  return response.data;
}, error => {
  if (error.response?.status === 401 || error.code === 'ERR_NETWORK') {
     //logout();
    return Promise.reject({
      status: 401,
      message: 'Network Error',
    });
  }

  console.log({error}, error?.code, "Aliiii");
  if (error.response?.status === 404){
    return Promise.reject({
      status: 404,
      message: 'Not Found',
    });
  }
  EventBus.$emit('loading', false);
  EventBus.$emit('showToast', error.response?.data?.error?.message || error.response?.statusText);
  return Promise.reject({
    status: error.response?.status,
    message: error.response?.data?.error?.message || error.response?.statusText,
  });
});

export default ApiService;