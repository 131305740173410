<script>

import {isAdmin} from "@/helpers";
export default {
  name: "NetaSoftSideBar",
  computed: {
    routes() {
      const routes = this.$router.options.routes.filter(f => f.meta && f.meta.tag !== 'noList');
      const userIsAdmin = isAdmin();
      if (!userIsAdmin) {
        return routes.filter(f => f.meta && f.meta.tag !== 'admin');
      }

      return routes;
    }
  }
}
</script>

<template>
  <div class="vertical-menu">
    <div data-simplebar="init" class="h-100 simplebar-scrollable-y" id="my-element">
      <div class="simplebar-wrapper" style="margin: 0px;">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
            <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                 style="height: 100%; overflow: hidden scroll;">
              <div class="simplebar-content" style="padding: 0px;">
                <div id="sidebar-menu">
                  <ul id="side-menu" class="metismenu list-unstyled">
                    <li v-for="route in routes" :key="route.path">
                      <router-link :to="route.path" class="side-nav-link-ref">
                        <font-awesome-icon :icon="route.meta.icon" />
                        <span style="margin-left: 10px">{{route.meta.text}}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="simplebar-placeholder" style="width: 250px; height: 1353px;"></div>
      </div>
      <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
        <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
      </div>
      <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
        <div class="simplebar-scrollbar"
             style="height: 539px; transform: translate3d(0px, 0px, 0px); display: block;"></div>
      </div>
    </div>
  </div>
</template>
