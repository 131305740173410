<script>

export default {
  name: "NetaSoftOffCanvas",
  props: {
    title: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'end'
    },
    canvasStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    offcanvasClass() {
      return `offcanvas offcanvas-${this.position}`;
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true;
      this.$emit('opened');
    },
    close() {
      this.show = false;
      this.$emit('closed');
    },
    handleClickBtnClose() {
      this.close();
    }
  },
}
</script>

<template>
  <div :class="[offcanvasClass, {show: show}]" :style="canvasStyle" tabindex="-1">
    <div class="offcanvas-header">
      <slot name="header"><h5>{{ title }}</h5></slot>
      <button type="button" class="btn-close text-reset" @click="handleClickBtnClose" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <slot></slot>
    </div>
  </div>
</template>