<script>
import NetaSoftPageFooter from "@/components/footer/index.vue";
import NetaSoftPageHeader from "@/components/header/index.vue";
import NetaSoftSideBar from "@/components/sideBar/index.vue";

export default {
  name: "NetaSoftPageLayout",
  components: {
    NetaSoftSideBar,
    NetaSoftPageHeader,
    NetaSoftPageFooter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    layoutClass: {
      type: String,
      default: "col-12",
    }
  },
}
</script>

<template>
  <div id="layout-wrapper">
    <NetaSoftPageHeader ref="header"/>
    <NetaSoftSideBar/>
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div :class="layoutClass">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between"><h4
                  class="mb-sm-0 font-size-18">{{ title }}</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div :class="layoutClass">
              <div class="card">
                <div class="card-body">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NetaSoftPageFooter/>
  </div>
</template>