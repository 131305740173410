<script>
import {EventBus} from "@/events/eventBus";

export default {
  name: 'SpinnerComponent',
  created() {
    EventBus.$on('loading', (value) => {
      this.show = value;
    });
  },
  destroyed() {
    EventBus.$off('loading');
  },
  data() {
    return {
      show: false
    };
  }
}
</script>

<template>
  <div class="spinner" v-show="show">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</template>

<style scoped>
.spinner {
  width: 100%; /* Genişliği %100 yap */
  height: 100%; /* Yüksekliği %100 yap */
  position: fixed; /* Pozisyonu fixed olarak belirle */
  top: 0; /* Üstten 0px uzaklıkta olmasını sağla */
  left: 0; /* Soldan 0px uzaklıkta olmasını sağla */
  z-index: 9999; /* Diğer elementlerin üzerinde görünmesini sağla */
  background: rgba(255, 255, 255, 0.8); /* Arka planı belirli bir opaklıkta beyaz yap */
  display: flex; /* Flexbox kullan */
  justify-content: center; /* İçerisindeki elementleri yatay eksende ortala */
  align-items: center; /* İçerisindeki elementleri dikey eksende ortala */
}

.double-bounce1, .double-bounce2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #556ee6;
  opacity: 0.6;
  position: absolute;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
}
</style>