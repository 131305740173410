<script>
import {getUser, logout} from "@/helpers";
import {getUnreadNotificationCount} from "@/api/notification";

export default {
  name: "NetaSoftPageHeader",
  computed: {
    username() {
      return getUser()?.userName;
    }
  },
  data() {
    return {
      showAvatarDropdown: false,
      notificationCount: 0,
      ticketCount: 0
    }
  },
  mounted() {
    this.loadNotificationCount();
  },
  methods: {
    handleClickBtnLogout() {
      logout();
    },
    setReadNotificationCount(count) {
      this.notificationCount = count;
    },
    async loadNotificationCount() {
      this.notificationCount = await getUnreadNotificationCount();
    },
    handleClickBtnNotification() {
      const url = this.$router.resolve({ path: '/notifications' }).href;
      window.location.href = url;
    },
    toggleSideBar() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        document.body.classList.toggle("vertical-collpsed");
      } else {
        document.body.classList.remove("vertical-collpsed");
      }
    }
  }
}
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <div class="navbar-brand-box">
          <a href="/">
            <img src="../../assets/images/logo.png" alt=""/>
          </a>
        </div>
        <button class="btn btn-md btn-white btn btn-sm px-3 font-size-16 header-item" type="button"
                id="vertical-menu-btn">
          <font-awesome-icon icon="bars" @click="toggleSideBar"/>
        </button>
      </div>
      <div class="d-flex">
        <div class="btn-group">
          <button
              @click="handleClickBtnNotification"
              class="btn btn-md btn-black header-item noti-icon dropdown-toggle"
              type="button">
            <font-awesome-icon icon="bell" size="lg" :shake="!!notificationCount"/>
            <span v-if="!!notificationCount" class="badge bg-danger rounded-pill">{{ notificationCount }}</span>
          </button>
          <div class="dropdown">
            <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-bs-toggle="dropdown" aria-expanded="false">
              <img
                  class="rounded-circle header-profile-user"
                  src="../../assets/images/user-avatar.png"
                  :alt="username">
              <span class="d-none d-xl-inline-block mx-1">
                <span>{{ username }}</span>
              </span>
              <font-awesome-icon icon="chevron-down"/>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <button class="btn btn-white dropdown-item" @click="handleClickBtnLogout">
                  <font-awesome-icon icon="arrow-right-from-bracket"/>
                  Çıkış Yap
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
.avatar-dropdown {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0px, 70px);
  max-height: 854px;
  max-width: 175px;
}
</style>