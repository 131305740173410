<script>
import formElementMixin from "@/mixins/formElementMixin";

export default {
  name: "NetaSoftTextArea",
  mixins: [formElementMixin],
  props: {
    rows: {
      type: Number,
      default: 3
    },
  }
}
</script>

<template>
  <div class="mb-3" role="group">
    <label ref="label" :for="id" class="form-label d-block"> {{ label }} </label>
    <div class="">
      <textarea
          :id="id"
          ref="input"
          v-model="model"
          :disabled="disabled"
          :placeholder="placeholder"
          :rows="rows"
          class="form-control"
          @change="handleInputChange"></textarea>
      <div v-if="hasError" class="error-message">{{ error }}</div>
    </div>
  </div>
</template>
