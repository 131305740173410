export const setLocalStorage = (key, value) => {
  const valueIsJson = typeof value === 'object';
  if (valueIsJson)
    localStorage.setItem(key, JSON.stringify(value));
  else
    localStorage.setItem(key, value);
}

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

export const getUser = () => {
  return getLocalStorage('user');
}

export const getToken = () => {
  return getLocalStorage('user')?.tokenBearer;
}

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
}

export const logout = () => {
  removeLocalStorage('user');
  window.location.reload('account/login');
}

export const isAdmin = () => {
  return getUser()?.isAdmin;
}

export const isMail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const processFiles = (origFiles, files,showToast) => {
  let totalSize = origFiles.reduce((acc, file) => acc + file.size, 0);

  let isAlert = false;

  Array.from(files).forEach(file => {
    totalSize += file.size; // her dosyanın boyutunu toplama ekleyin

    if (totalSize > 10 * 1024 * 1024) { // toplam boyut 10MB'den büyükse
      !isAlert && showToast('Toplam dosya boyutu 10MB üstü olamaz.'); // bir hata mesajı gösterin
      isAlert = true; // hata mesajını bir kez göstermek için
      return; // işlemi sonlandırın
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      let base64String = event.target.result;
      base64String = base64String.split(',')[1]; // MIME tipini ve başındaki 'data:' ve 'base64,' kısımlarını kaldır

      origFiles.push({
        fileName: file.name.split('.').slice(0, -1).join('.'),
        fullName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileExtension: file.name.split('.').pop(),
        base64: base64String
      });
    };

    reader.readAsDataURL(file);
  });

  return origFiles;
}

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return 'danger';
    case 2:
      return 'success';
    case 3:
      return 'primary';
    case 4:
      return 'info';
    default:
      return '';
  }
}