<script>
export default {
   name: 'NetaSoftModal',
  computed: {
    modalClass() {
      return `modal fade ${this.show ? 'show' : ''}`;
    },
    modalStyle() {
      return {
        display: this.show ? 'block' : 'none'
      }
    },
    modalSize() {
      return `modal-dialog ${this.size}`
    },
    slotData() {
      return {
        slots: this.$slots,
        scopedSlots: this.$scopedSlots,
        model: this.model,
        show: this.show
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    successButtonText: {
      type: String,
      default: 'Kaydet'
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    closeButtonText: {
      type: String,
      default: 'Kapat'
    },
    size: {
      type: String,
      default: 'modal-lg'
    },
    successClass: {
      type: String,
      default: 'btn btn-success'
    },
    cancelClass: {
      type: String,
      default: 'btn btn-secondary'
    },
    hideFooter: Boolean
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    open() {
      this.show = true;
      this.$emit('opened', this.slotData)
    },
    close() {
      this.show = false;
      this.$emit('closed', this.slotData)
    },
    handleClickCloseButton() {
      this.close();
    },
    handleClickSuccessButton() {
      this.$emit('success', this.slotData)
    }
  }
}
</script>

<template>

  <div :class="modalClass" :style="modalStyle" tabindex="-1">
    <div :class="modalSize">
      <div class="modal-content">
        <div class="modal-header bg-info">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="handleClickCloseButton"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-show="!hideFooter">
          <slot name="buttons">
            <button v-if="closeButton" type="button" :class="cancelClass" @click="handleClickCloseButton">{{ closeButtonText }}</button>
            <button type="button" :class="successClass" @click="handleClickSuccessButton">{{ successButtonText }}</button>
            <slot name="buttonSuccessAfter"></slot>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
