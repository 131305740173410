<template>
  <div id="app">
    <spinner />
    <router-view></router-view>
  </div>
</template>

<script>
import Spinner from "@/components/spinner/index.vue";

export default {
  name: 'App',
  components: {Spinner},
}
</script>

<style>
@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
}

@import "assets/styles/theme.css";
@import "assets/styles/custom.css";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  line-height: 1.47;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
