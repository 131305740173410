<template>
  <div class="mb-3" role="group">
    <label v-if="!!label" ref="label" class="form-label d-block"> {{ label }} </label>
    <div class="">
      <ckeditor :disabled="disabled" class="my-editor" @dialogDefinition="han" :editor="editor" v-model="model" :config="editorConfig"></ckeditor>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';

export default {
  name: 'NetaSoftTextEditor',
  components: {
    ckeditor: CKEditor.component
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  props: {
    value: String,
    label: String,
    disabled: Boolean
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        height: '200px',
        removeDialogTabs: 'link:upload;image:Upload'
      }
    };
  },
  methods: {
    han(e) {
      console.log(e);
    }
  }
}
</script>

<style >
.ck-editor__editable {
  height: 200px;
  margin-bottom: 20px;
}
.ck.ck-button.ck-off.ck-file-dialog-button{
  display: none;
}
.ck.ck-button.ck-off.ck-dropdown__button{
  display: none;
}
</style>