import Vue from 'vue'

import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import VueTheMask from 'vue-the-mask';
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import App from './App.vue'
import router from "./router";
import './icons/fontAwesome.js';
import './helpers/filters';


import NetaSoftPageLayout from "@/layouts/pageLayout/index.vue";
import NetaSoftPageModal from "@/components/modal/index.vue";
import NetaSoftOffCanvas from "@/components/offcanvas/index.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import NetaSoftInput from "@/components/input/index.vue";
import NetaSoftForm from "@/components/form/index.vue";
import NetaSoftTextArea from "@/components/textArea/index.vue";
import NetaSoftDeleteConfirm from "@/components/deleteConfirm/index.vue";
import NetaSoftFormSuccessButton from "@/components/formSuccessButton/index.vue";
import NetaSoftTicketStatusSelect from "@/components/selects/ticketStatusSelect.vue";
import NetaSoftTextEditor from "@/components/textEditor/index.vue";

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('page-layout', NetaSoftPageLayout);
Vue.component('page-modal', NetaSoftPageModal);
Vue.component('page-canvas', NetaSoftOffCanvas);
Vue.component('page-input', NetaSoftInput);
Vue.component('page-form', NetaSoftForm);
Vue.component('page-text-area', NetaSoftTextArea);
Vue.component("page-delete-confirm", NetaSoftDeleteConfirm);
Vue.component("page-form-success-button", NetaSoftFormSuccessButton);
Vue.component("ticket-status-select", NetaSoftTicketStatusSelect)
Vue.component("text-editor", NetaSoftTextEditor)

Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(Toast, {
    position: 'top-right',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
});